export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signupimg = require("../assets/signupimg.jpg");
export const logo = require("../assets/Logo.png");
export const stories = require("../assets/stories.png");
export const videos = require("../assets/videos.png");
export const news = require("../assets/news.png");
export const articles = require("../assets/Articles.png");
export const partners = require("../assets/partners.png");
export const testimonials = require("../assets/testimonials.png");
export const FAQ = require("../assets/FAQs.png");
export const teams = require("../assets/teams.png");
export const forms = require("../assets/forms.png");
export const banners = require("../assets/Banners.png");
export const settings = require("../assets/settings.png");
export const logout = require("../assets/logout.png");
export const search = require("../assets/search-icon.png");
export const readmore = require("../assets/Dot.webp");
export const profile = require("../assets/profilePicture.png");
export const edit = require("../assets/Edit-icon.png");
export const edit_white = require("../assets/Edit-icon-white.png");
export const delete_img = require("../assets/deleteimg.png");
export const left_image_arrow = require("../assets/left-arrow.png");
export const right_image_arrow = require("../assets/right-arrow.png");
export const deletImage = require("../assets/delete.png");
export const Imageicon = require("../assets/image.png");
export const stories_gray = require("../assets/Stories_gray.png");
export const Video_blueClr = require("../assets/Video_blueClr.png");
export const News_blue = require("../assets/News_blue.png");
export const Articles_blue = require("../assets/Articles_blue.png");
export const partners_blue = require("../assets/partners_blue.png");
export const Testimonials_blue = require("../assets/Testimonials_blue.png");
export const Faq_blue = require("../assets/Faq_blue.png");
export const Teams_blue = require("../assets/Teams_blue.png");
export const Forms_blue = require("../assets/Forms_blue.png");
export const Banner_blue = require("../assets/Banner_blue.png");
export const setting_blue = require("../assets/setting_blue.png");
export const privacy = require('../assets/privacy.png');
export const privacy_blue = require('../assets/privacyactive.png');
export const terms = require('../assets/terms.png');
export const terms_blue = require('../assets/termsactive.png');
export const BLthumbnail = require("../assets/BL-learn.png");
export const Experiential_Learning = require("../assets/Experiential_Learning.png");
export const Experiential_Learning_active = require("../assets/Experiential_Learning_active.png");
export const Hours_of_Coding = require("../assets/Hours_of_Coding.png");
export const Live_Sessions = require("../assets/Live_Sessions.png");
export const Weeks_Online_Program = require("../assets/Weeks_Online_Program.png");
