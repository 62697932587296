import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { logo, articles, FAQ, forms, news, partners, stories, stories_gray, teams, testimonials, videos, logout, settings, Video_blueClr, News_blue, Forms_blue, Teams_blue, Faq_blue, Testimonials_blue, partners_blue, Articles_blue, setting_blue, Experiential_Learning, Experiential_Learning_active, terms, terms_blue, privacy_blue, privacy } from '../../blocks/AdminConsole/src/assets'

const { Content, Sider } = Layout;

const EmployeLayout = (props: any) => {

    const handleLogout = () => {
        localStorage.clear();
        window.location.pathname = '/'
    }

    // Customizable Area Start
    return (
        <>
            <Layout className="layout-container">
                <Sider
                    breakpoint="md"
                    collapsedWidth="0"
                    onBreakpoint={(broken) => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                    className="indv-dash-sidebar sidebarWidth">
                    <div className="indv-logo-head ">
                        <Link to="/">
                            <img src={logo} alt="LOGO" className="layout-logo" />
                        </Link>
                    </div>
                    <Menu theme="dark" mode="inline" className="menumargin">
                        <Menu.Item key="1">
                            <Link
                                to="/admin/stories"
                                className={
                                    window.location.pathname === "/admin/stories"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/stories" || window.location.pathname === "/admin/editstory" || window.location.pathname === "/admin/viewstory" ? <img src={stories} alt="logo" className="homeimg" /> : <img src={stories_gray} alt="logo" className="homeimg" />
                                    }
                                </span>
                                Stories
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link
                                to="/admin/videostories"
                                className={
                                    window.location.pathname === "/admin/videostories"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/videostories" || window.location.pathname === "/admin/addVideos" || window.location.pathname === "/admin/viewvideos" || window.location.pathname === "/admin/editVideos" ? <img src={Video_blueClr} alt="logo" /> : <img src={videos} alt="logo" />
                                    }
                                </span>
                                Videos
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <Link
                                to="/admin/news"
                                className={
                                    window.location.pathname === "/admin/news"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/news" || window.location.pathname === "/admin/addNews" || window.location.pathname === "/admin/viewnews/" || window.location.pathname === "/admin/editNews" ? <img src={News_blue} alt="logo" /> : <img src={news} alt="logo" />
                                    }
                                </span>
                                News
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="4">
                            <Link
                                to="/admin/articles"
                                className={
                                    window.location.pathname === "/admin/articles"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/articles" || window.location.pathname === "/admin/addBridgelabz" || window.location.pathname === "/admin/viewbridgelabz" || window.location.pathname === "/admin/editBridgelabz/42" ? <img src={Articles_blue} alt="logo" /> : <img src={articles} alt="logo" />
                                    }
                                </span>
                                Articles
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="5">
                            <Link
                                to="/admin/partners"
                                className={
                                    window.location.pathname === "/admin/partners"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/partners" || window.location.pathname === "/admin/addPartner" || window.location.pathname === "/admin/editPartner" ? <img src={partners_blue} alt="logo" /> : <img src={partners} alt="logo" />
                                    }
                                </span>
                                Partners
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="6">
                            <Link
                                to="/admin/testimonials"
                                className={
                                    window.location.pathname === "/admin/testimonials"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/testimonials" || window.location.pathname === "/admin/editTestimonial" || window.location.pathname === "/admin/viewTestimonial" ? <img src={Testimonials_blue} alt="logo" /> : <img src={testimonials} alt="logo" />
                                    }
                                </span>
                                Testimonials
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7">
                            <Link
                                to="/admin/faq"
                                className={
                                    window.location.pathname === "/admin/faq"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/faq" || window.location.pathname === "/admin/AddFaq" || window.location.pathname === "/admin/EditFaq" || window.location.pathname === "/admin/ViewFaq" ? <img src={Faq_blue} alt="logo" /> : <img src={FAQ} alt="logo" />
                                    }
                                </span>
                                FAQs
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="8">
                            <Link
                                to="/admin/teams"
                                className={
                                    window.location.pathname === "/admin/teams"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/teams" || window.location.pathname === "/admin/AddTeams" || window.location.pathname === "/admin/editTeams" || window.location.pathname === "/admin/viewTeams" ? <img src={Teams_blue} alt="logo" /> : <img src={teams} alt="logo" />
                                    }
                                </span>
                                Teams
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="9">
                            <Link
                                to="/admin/bannersforms"
                                className={
                                    window.location.pathname === "/admin/bannersforms"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/bannersforms" || window.location.pathname === "/admin/AddEditbannersforms" || window.location.pathname === "/admin/viewbannersforms" ? <img src={Forms_blue} alt="logo" /> : <img src={forms} alt="logo" />
                                    }
                                </span>
                                Forms & Banners
                            </Link>
                        </Menu.Item>
                        
                        <Menu.Item key="11">
                            <Link
                                to="/admin/privacy"
                                className={
                                    window.location.pathname === "/admin/privacy"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/privacy" || window.location.pathname === "/admin/addEditPrivacy" || window.location.pathname === "/admin/viewPrivacy" ? <img src={privacy_blue} alt="logo" /> : <img src={privacy} alt="logo" />
                                    }
                                </span>
                                Privacy Policy
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="12">
                            <Link
                                to="/admin/terms"
                                className={
                                    window.location.pathname === "/admin/terms"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/terms" || window.location.pathname === "/admin/addEditTerms" || window.location.pathname === "/admin/viewTerms" ? <img src={terms_blue} alt="logo" /> : <img src={terms} alt="logo" />
                                    }
                                </span>
                                Terms & Conditions
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="13">
                            <Link
                                to="/admin/Experiential"
                                className={
                                    window.location.pathname === "/admin/Experiential"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/Experiential" ? <img src={Experiential_Learning_active} alt="logo" /> : <img src={Experiential_Learning} alt="logo" />
                                    }
                                </span>
                                Experiential Learning
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="14" className="settings_div">
                            <Link
                                to="/admin/settings"
                                className={
                                    window.location.pathname === "/admin/settings"
                                        ? "active-link"
                                        : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                    {
                                        window.location.pathname === "/admin/settings" || window.location.pathname === "/admin/editsetting" ? <img src={setting_blue} alt="logo" /> : <img src={settings} alt="logo" />
                                    }
                                </span>
                                Settings
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="15" className="log_out_div">
                            <div className="logout" onClick={() => handleLogout()}>
                                <span className="indv-dash-side-img">
                                    <img src={logout} alt="logo" />
                                </span>
                                Log Out
                            </div>
                        </Menu.Item>
                    </Menu>

                </Sider>
                <Layout className="indv-layout">
                    <Content>{props.children}</Content>
                </Layout>
            </Layout>
        </>
    );
    // Customizable Area End
};
// Customizable Area Start
export default EmployeLayout;
// Customizable Area End