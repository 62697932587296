//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';
//* Routes List start

const AdminStories = lazy(() => import('../../blocks/AdminConsole/src/success_stories/AdminStories.web'));
const Login = lazy(() => import('../../blocks/AdminConsole/src/signup/Login.web'));
const ViewStory = lazy(() => import('../../blocks/AdminConsole/src/success_stories/ViewStory.web'));
const EditStory = lazy(() => import('../../blocks/AdminConsole/src/success_stories/EditStory.web'));
const VideoStories = lazy(() => import("../../blocks/AdminConsole/src/videos/video_stories.web"));
const ViewVideos = lazy(() => import("../../blocks/AdminConsole/src/videos/ViewVideos.web"));
const EditVideos = lazy(() => import("../../blocks/AdminConsole/src/videos/EditVideos.web"));
const BridgelabzSpeaks = lazy(() => import("../../blocks/AdminConsole/src/BridgeLabzSpeaks/BridgelabzSpeaks.web"))
const ViewBridgelabzSpeaks = lazy(() => import("../../blocks/AdminConsole/src/BridgeLabzSpeaks/ViewBridgelabzSpeaks.web"))
const EditBridgelabzSpeaks = lazy(() => import("../../blocks/AdminConsole/src/BridgeLabzSpeaks/EditBridgelabzSpeaks.web"))
const AdminPrivacyPolicy = lazy(() => import("../../blocks/AdminConsole/src/PrivacyPolicy/PrivacyPolicy.web"))
const ViewPrivacyPolicy = lazy(() => import("../../blocks/AdminConsole/src/PrivacyPolicy/ViewPrivacyPolicy.web"))
const EditPrivacyPolicy = lazy(() => import("../../blocks/AdminConsole/src/PrivacyPolicy/EditPrivacyPolicy.web"))
const Terms = lazy(() => import("../../blocks/AdminConsole/src/Terms/Terms.web"))
const ViewTerms = lazy(() => import("../../blocks/AdminConsole/src/Terms/ViewTerms.web"))
const EditTerms = lazy(() => import("../../blocks/AdminConsole/src/Terms/EditTerms.web"))
const Faq = lazy(() => import("../../blocks/AdminConsole/src/FAQ's/Faq.web"))
const EditFaq = lazy(() => import("../../blocks/AdminConsole/src/FAQ's/EditFaq.web"))
const ViewFaq = lazy(() => import("../../blocks/AdminConsole/src/FAQ's/ViewFaq.web"))
const PartnerCompanies = lazy(() => import("../../blocks/AdminConsole/src/companies/partnerCompanies.web"));
const ViewPartnerCompanies = lazy(() => import("../../blocks/AdminConsole/src/companies/viewPartnersCompanies.web"));
const EditPartnerCompanies = lazy(() => import("../../blocks/AdminConsole/src/companies/EditPartnerCompanies.web"));
const CorporateTestimonials = lazy(() => import("../../blocks/AdminConsole/src/testimonials/CorporateTestimonials.web"));
const EditTestimonials = lazy(() => import("../../blocks/AdminConsole/src/testimonials/EditTestimonials.web"));
const ViewTestimonials = lazy(() => import("../../blocks/AdminConsole/src/testimonials/ViewTestimonials.web"));
const NewsAboutus = lazy(() => import("../../blocks/AdminConsole/src/NewsAboutus/News.web"));
const ViewNewsAboutus = lazy(() => import("../../blocks/AdminConsole/src/NewsAboutus/ViewNewsAboutUs.web"));
const EditNewsAboutus = lazy(() => import("../../blocks/AdminConsole/src/NewsAboutus/EditNews.web"));
const ContactUs = lazy(() => import("../../blocks/customform/src/ContactUs.web"))
const MoreInfo = lazy(() => import("../../blocks/customform/src/GetMoreInfo.web"))
const Scholarship = lazy(() => import("../../blocks/customform/src/Scholarship.web"))
const Bteq = lazy(() => import("../../blocks/landingpage/src/ExperiencedForms/BTEQ.web"))
const ScholarshipFrom = lazy(() => import("../../blocks/landingpage/src/ScholarshipForm/ScholarshipFrom.web"));
const CareerGrowthForm = lazy(() => import("../../blocks/landingpage/src/CareerGrowthForm/CareerGrowthForm.web"));
const Ebook = lazy(() => import("../../blocks/landingpage/src/ExperiencedForms/Ebook.web"))

const BusinessPage = lazy(() =>
    import('../../blocks/landingpage/src/Business/BusinessLandingPage.web')
);
const LandingPageWeb = lazy(() =>
    import('../../blocks/landingpage/src/LandingPage.web')
);
const AllNewsAboutUs = lazy(() =>
    import('../../blocks/landingpage/src/AllNewsAboutUs.web')
);

const IncubationsPageWeb = lazy(() =>
    import('../../blocks/landingpage/src/Incubations/IncubationPage.web')
);

const ExprienceDashboard = lazy(() =>
    import('../../blocks/landingpage/src/ExperienceEngineer/ExprienceDashboard.web')
);
const Team = lazy(() => import("../../blocks/AdminConsole/src/Team/Team.web"));
const EditTeam = lazy(() => import("../../blocks/AdminConsole/src/Team/EditTeam.web"));
const ViewTeam = lazy(() => import("../../blocks/AdminConsole/src/Team/ViewTeam.web"));
const BannerForm = lazy(() =>
    import('../../blocks/AdminConsole/src/Forms/Forms.web')
);
const ViewBannerForm = lazy(() =>
    import('../../blocks/AdminConsole/src/Forms/ViewForms.web')
);
const EditBannerForm = lazy(() =>
    import('../../blocks/AdminConsole/src/Forms/EditForms.web')
);
const EditSetting = lazy(() =>
    import('../../blocks/AdminConsole/src/Settings/EditSetting.web')
);
const Settings = lazy(() =>
    import('../../blocks/AdminConsole/src/Settings/Settings.web')
);
const DownloadNow = lazy(() =>
    import('../../blocks/landingpage/src/DownloadNowFrom/DownloadNow.web')
);
const ExperientialLearning = lazy(() =>
    import('../../blocks/AdminConsole/src/Experiential_Learning/ExperientialLearning.web')
);
const TermsAndConditions = lazy(()=> 
    import ('../../blocks/landingpage/src/TermsAndPolicy/TermsAndConditions.web')
);
const LandingPagePrivacyPolicy = lazy(()=> 
    import ('../../blocks/landingpage/src/TermsAndPolicy/PrivacyPolicy.web')
);

// /* Routes List End /

// /* Private Routes start /
import Loader from '../../components/src/Loader.web';
import AdminLayout from '../../components/src/AdminLayout.web';
import PrivateRoute from './PrivateRoute.web'

// /* Private Roues End /

function WebRoutes(rootProps: any) {
    return (
        <Suspense fallback={<Loader loading />}>
            <Switch>
                <Route
                    path="/"
                    exact
                    render={props => <Login {...props} />}
                />
                <Route
                    path="/home"
                    exact
                    render={props => <LandingPageWeb {...props} />}
                />
                <Route
                    path="/viewAllNews"
                    exact
                    render={props => <AllNewsAboutUs {...props} />}
                />

                <Route
                    path="/contactus"
                    exact
                    render={props => <ContactUs {...props} />}
                />
                <Route
                    path="/moreinfo"
                    exact
                    render={props => <MoreInfo {...props} />}
                />
                <Route
                    path="/scolarship"
                    exact
                    render={props => <Scholarship {...props} />}
                />
                <Route
                    path="/ebook"
                    exact
                    render={props => <Ebook {...props} />}
                />
                <Route
                    path="/eBookDownload"
                    exact
                    render={props => <DownloadNow {...props} />}
                />
                <Route
                    path="/bteq"
                    exact
                    render={props => <Bteq {...props} />}
                />
                <Route
                    path="/home/scholarshipForm"
                    exact
                    render={props => <ScholarshipFrom {...props} />}
                />
                <Route
                    path="/user_register"
                    exact
                    render={props => <CareerGrowthForm {...props} />}
                />
                <Route
                    path="/business"
                    exact
                    render={props => <BusinessPage {...props} />}
                />
                <Route
                    path="/incubations"
                    exact
                    render={props => <IncubationsPageWeb {...props} />}
                />
                <Route
                    path="/exprienceengineer"
                    exact
                    render={props => <ExprienceDashboard {...props} />}
                />
                 <Route
                    path="/privacypolicy"
                    exact
                    render={props => <LandingPagePrivacyPolicy {...props}/>}
                />
                 <Route
                    path="/termsandconditions"
                    exact
                    render={props => <TermsAndConditions {...props} />}
                />
                <Route path="/admin">
                    <AdminLayout>
                        <PrivateRoute
                            path="/admin/stories"
                            exact
                            render={props => <AdminStories {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewstory"
                            exact
                            render={props => <ViewStory {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editstory"
                            exact
                            render={props => <EditStory {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/videostories"
                            exact
                            render={props => <VideoStories {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewvideos"
                            exact
                            render={props => <ViewVideos {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editVideos"
                            exact
                            render={props => <EditVideos {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/news"
                            exact
                            render={props => <NewsAboutus {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewnews"
                            exact
                            render={props => <ViewNewsAboutus {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/addNews"
                            exact
                            render={props => <EditNewsAboutus {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editNews/:itemId?"
                            exact
                            render={props => <EditNewsAboutus {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/articles"
                            exact
                            render={props => <BridgelabzSpeaks {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewbridgelabz/"
                            exact
                            render={props => <ViewBridgelabzSpeaks {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/addBridgelabz"
                            exact
                            render={props => <EditBridgelabzSpeaks {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/privacy"
                            exact
                            render={props => <AdminPrivacyPolicy {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewPrivacy/"
                            exact
                            render={props => <ViewPrivacyPolicy {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editPrivacy/:itemId?"
                            exact
                            render={props => <EditPrivacyPolicy {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/addPrivacy"
                            exact
                            render={props => <EditPrivacyPolicy {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editBridgelabz/:itemId?"
                            exact
                            render={props => <EditBridgelabzSpeaks {...props} />}
                        />
                         <PrivateRoute
                            path="/admin/terms"
                            exact
                            render={props => <Terms {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewTerms/"
                            exact
                            render={props => <ViewTerms {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editTerms/:itemId?"
                            exact
                            render={props => <EditTerms {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/addTerms"
                            exact
                            render={props => <EditTerms {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/partners"
                            exact
                            render={props => <PartnerCompanies {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewPartner"
                            exact
                            render={props => <ViewPartnerCompanies {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/addPartner"
                            exact
                            render={props => <EditPartnerCompanies {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editPartner"
                            exact
                            render={props => <EditPartnerCompanies {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/testimonials"
                            exact
                            render={props => <CorporateTestimonials {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editTestimonial"
                            exact
                            render={props => <EditTestimonials {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewTestimonial"
                            exact
                            render={props => <ViewTestimonials {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/faq"
                            exact
                            render={props => <Faq {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/AddFaq"
                            exact
                            render={props => <EditFaq {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/EditFaq/:id"
                            exact
                            render={props => <EditFaq {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/ViewFaq"
                            exact
                            render={props => <ViewFaq {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/teams"
                            exact
                            render={props => <Team {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/AddTeams"
                            exact
                            render={props => <EditTeam {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editTeams/:id"
                            exact
                            render={props => <EditTeam {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewTeams"
                            exact
                            render={props => <ViewTeam {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/bannersforms"
                            exact
                            render={props => <BannerForm {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/viewbannersforms"
                            exact
                            render={props => <ViewBannerForm {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/AddEditbannersforms"
                            exact
                            render={props => <EditBannerForm {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/settings"
                            exact
                            render={props => <Settings {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/editsetting"
                            exact
                            render={props => <EditSetting {...props} />}
                        />
                        <PrivateRoute
                            path="/admin/Experiential"
                            exact
                            render={props => <ExperientialLearning {...props} />}
                        />
                    </AdminLayout>
                </Route>
            </Switch>
        </Suspense>
    );
}

//@ts-ignore
export default withRouter(WebRoutes);
